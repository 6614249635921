import { h, FunctionalComponent } from 'preact'
import { useState, useRef, useEffect, useContext } from 'preact/hooks'
import useSupabase from '../hooks/useSupabase'
import useUser from '../hooks/useUser'
import useAckee from '../hooks/useAckee'
import { NotificationContext } from './notification-area'
import { CheckCircleIcon } from '@heroicons/react/outline'

const DEFAULT_QUESTION = 'How are you finding Turbo Phonebank?'
const EMOJIS = [
  { emoji: '😭', title: 'It\'s horrible!' },
  { emoji: '😕', title: 'Not great' },
  { emoji: '🙂', title: 'Pretty good' },
  { emoji: '🤩', title: 'It\'s amazing!' }
]

const Feedback: FunctionalComponent<{ class?: string, question?: string, phonebankId?: string }> = ({ class: className, phonebankId, question = DEFAULT_QUESTION }) => {
  const supabase = useSupabase()
  const { showNotification } = useContext(NotificationContext)
  const { user } = useUser()
  const [rating, setRating] = useState(undefined as number | undefined)
  const [comments, setComments] = useState('')
  const [error, setError] = useState(undefined as Error | undefined)
  const ackee = useAckee()
  if (error) {
    throw error
  }

  const onSubmit = async (e: Event) => {
    e.preventDefault()
    if (!rating) {
      return
    }
    console.log('submitting feedback')
    ackee?.action('2fef6668-ba34-41a7-9877-5e735ea8b366', {
      key: 'submit feedback',
      value: rating
    })

    const { error } = await supabase.from('feedback')
      .insert({
        created_by: user!.id,
        phonebank: phonebankId,
        url: window.location.href,
        rating,
        comments
        // Note: because of the row level security policy,
        // it needs to return minimal (because users cannot SELECT)
      }, { returning: 'minimal' })
    if (error) {
      console.error('error saving feedback:', error)
      setError(new Error(`Error saving feedback: ${error.message}`))
      return
    }

    setRating(undefined)
    setComments('')
    showNotification({
      title: 'Feedback Submitted',
      text: 'Thank you for helping improve Turbo Phonebank',
      icon: <CheckCircleIcon className='h-6 w-6 text-green-600' aria-hidden='true' />,
      timeout: 5000
    })
  }

  if (!user) {
    return null
  }

  const ref = useRef(undefined as HTMLTextAreaElement | undefined)
  useEffect(() => {
    ref.current?.focus()
    ref.current?.scrollIntoView()
  }, [rating])

  let commentsPlaceholder = ''
  if (rating === 1) {
    commentsPlaceholder = 'I\'m sorry you\'re not having a good experience. What could I improve?'
  } else if (rating === 2) {
    commentsPlaceholder = 'What could be improved?'
  } else if (rating === 3) {
    commentsPlaceholder = 'What do you like? And what could be better?'
  } else if (rating === 4) {
    commentsPlaceholder = 'Excellent! What do you like about it?'
  }

  return (
    <div class={`lg:max-w-sm mx-auto flex flex-col gap-3 justify-items-center text-sm text-center ${className || ''}`}>
      <p class={rating === undefined ? 'text-gray-400' : 'text-gray-700'}>{question}</p>

      <div class='flex gap-x-5 justify-items-center mx-auto text-2xl'>
        {EMOJIS.map(({ emoji, title }, index) => (
          <button onClick={() => {
            if (rating === index + 1) {
              setRating(undefined)
            } else {
              setRating(index + 1)
            }
          }} title={title} class={
            // If none is selected, set them all to 70% opacity
            // If one is selected, set it to 100% and the others to 50%
            `${rating === undefined
              ? 'opacity-50'
              : rating === index + 1 ? 'opacity-100' : 'opacity-30'} hover:opacity-100`}>
            {emoji}
          </button>
        ))}
      </div>

      {
        // Only show input box after rating is selected
        rating
          ?
          <form onSubmit={onSubmit} class='flex flex-col justify-items-center gap-3'>
            <textarea value={comments} onInput={(e: any) => setComments(e.target.value)} ref={ref}
              placeholder={commentsPlaceholder} rows={2} class='rounded-md w-full border border-gray-300 focus:border-indigo-500 sm:text-sm text-gray-900' />
            <button type='submit' class='rounded-md bg-indigo-600 hover:bg-indigo-900 p-1 text-white font-medium text-xs py-1 px-2 mx-auto'>
              Submit
            </button>
          </form>
          : null
      }
    </div>
  )
}

export default Feedback
