import useSWR from 'swr'
import useSupabase from './useSupabase'
import useUser from './useUser'
import { Contact, PhonebankMode } from '../lib/types'

const TEST_CONTACT: Contact = {
  id: 'TEST',
  firstName: 'Test',
  lastName: 'Person',
  phoneNumber: '1234567890',
  additionalFields: {
    firstName: 'Test',
    lastName: 'Person'
  }
}
const MAX_RETRIES = 3

const randomId = () => '' + Math.random() * Number.MAX_SAFE_INTEGER

export default function useCurrentContact(phonebankId: string, mode: PhonebankMode, revalidateOnMount: boolean) {
  const { user } = useUser()
  let previousTestContactId: string | undefined

  const { data, error, mutate } = useSWR(['phonebank', phonebankId, 'mode', mode, 'contact'], async (): Promise<{ contact?: Contact, phonebankComplete: boolean }> => {
    console.log('loading contact')
    let contact: Contact | undefined
    if (mode === PhonebankMode.NORMAL) {
      contact = await loadRealContact(phonebankId, user!.id)
    } else if (mode === PhonebankMode.TRAINING) {
      contact = await loadTestContact(phonebankId, previousTestContactId)
      // Save the previous ID so we don't load the same contact twice in a row
      // but set the ID to a random one so that we ensure the rest of the system
      // treats this as a new contact (even if we've already loaded this test contact before)
      previousTestContactId = contact.id
      contact.id = randomId()
    } else {
      contact = await loadDemoContact()
    }
    return {
      contact,
      phonebankComplete: !contact
    }
  }, {
    revalidateOnMount: mode === PhonebankMode.NORMAL && revalidateOnMount,
    revalidateOnFocus: mode === PhonebankMode.NORMAL,
    revalidateOnReconnect: mode === PhonebankMode.NORMAL
  })

  return {
    contact: data?.contact,
    phonebankComplete: data?.phonebankComplete,
    error,
    mutateCurrentContact: mutate
  }
}

export async function loadRealContact(phonebankId: string, userId: string): Promise<Contact | undefined> {
  const supabase = useSupabase()

  const { data, error } = await supabase.rpc('current_or_next_contact', {
    user_id: userId,
    phonebank_id: phonebankId
  }).single()
  if (error) {
    console.error('error loading contact', error)
    throw new Error(`Error loading contact: ${error.message}`)
  }

  if (!data) {
    return undefined
  }

  let contact: Contact
  if (typeof data === 'object') {
    contact = data
  } else {
    try {
      contact = JSON.parse(data)
    } catch (err) {
      console.error(err)
      throw new Error('Got invalid JSON when loading next contact')
    }
  }

  return contact
}

async function loadTestContact(phonebankId: string, previousContactId?: string): Promise<Contact> {
  const supabase = useSupabase()

  let query = supabase.from('test_contacts')
    .select('details')
    .eq('phonebank', phonebankId)

  // Don't load the same contact twice in a row
  // (because it won't be treated as a new contact)
  if (previousContactId) {
    query = query.neq('id', previousContactId)
  }

  const { data: contacts, error } = await query
  if (error) {
    console.error(error)
  }
  if (contacts && contacts.length > 0) {
    const randomIndex = Math.floor(Math.random() * contacts.length)
    try {
      const details = contacts[randomIndex].details
      let contact: Contact
      if (typeof details === 'object') {
        contact = details
      } else {
        contact = JSON.parse(details)
      }
      return contact
    } catch (err) {
      console.warn('Invalid test contact', contacts[randomIndex].details, err)
    }
  }
  return {
    ...TEST_CONTACT,
    id: randomId()
  }
}

async function loadDemoContact(): Promise<Contact> {
  const { randomContact, randomDelay } = await import('../lib/random-contact')
  await randomDelay()
  return randomContact()
}
