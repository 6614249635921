import { h, FunctionalComponent } from 'preact'
import { route } from 'preact-router'

type RedirectProps = { to: string, path: string } | { replace: RegExp, with: string, path: string }

const Redirect: FunctionalComponent<RedirectProps> = (props: any) => {
  if (props.to) {
    route(props.to, true)
  } else {
    route(props.path.replace(props.replace, props.with))
  }
  return null
}

export default Redirect
