import { h, FunctionalComponent, JSX, VNode } from 'preact'
import { useMemo, useEffect, useRef, useState, useContext } from 'preact/hooks'
import useUser from '../../hooks/useUser'
import { ScriptQuestion, toRgbColor, Contact, ResponseInput, ResponseButton, ResponseSmsLink, PhonebankMode } from '../../lib/types'
import ScriptText from './script-text'
import { NotificationContext } from '../notification-area'
import { ClipboardCopyIcon } from '@heroicons/react/outline'
import useScriptState from '../../hooks/useScriptState'
import useCurrentContact from '../../hooks/useCurrentContact'
import { scrollIntoView } from '../../lib/scroll'

const USER_NAME_SUBSTITUTION_REGEX = /\{\{(?:your|user|caller) name\}\}/giu

export function substituteValuesText(text: string, contact?: Contact, firstName?: string): string {
  if (firstName) {
    text = text.replace(USER_NAME_SUBSTITUTION_REGEX, firstName)
  }

  if (contact) {
    const fieldsToSubstitute = contact.additionalFields || {}
    for (const field in fieldsToSubstitute) {
      const textToReplace = `{{${field}}}`
      text = text.replaceAll(textToReplace, fieldsToSubstitute[field])
    }
  }

  return text
}

const SaveLoadNextButton: FunctionalComponent<{ id: string, mode: PhonebankMode }> = ({ id, mode }) => {
  const { saveResponsesAndLoadNextContact } = useScriptState(id, mode, false)
  // Focus on the button so you can continue by hitting the Enter key again
  const submit = useRef(null as any)
  useEffect(() => { submit && submit.current && submit.current.focus() }, [submit])
  const onSubmit = (e: any) => {
    e.preventDefault()
    saveResponsesAndLoadNextContact()
  }

  return (
    <form onSubmit={onSubmit}>
      <input ref={submit} type='submit' class='text-gray-50 bg-blue-600 border rounded-md hover:bg-blue-800 py-2 px-4 w-full cursor-pointer' value='Save & Load Next Contact' />
    </form>
  )
}

const ScriptButton: FunctionalComponent<{
  id: string,
  mode: PhonebankMode,
  responseOption: ResponseButton | ResponseSmsLink,
  question: ScriptQuestion,
  responseIndex: number,
  questionIndex: number
}> = ({ id, mode, question, responseIndex, questionIndex, responseOption }) => {
  const { showNotification } = useContext(NotificationContext)
  const { user } = useUser()
  const { currentPage, pageIndex, saveQuestionResponse } = useScriptState(id, mode, false)
  const { contact } = useCurrentContact(id, mode, false)
  const [error, setError] = useState(undefined as Error | undefined)

  if (!currentPage || !contact) {
    return null
  }

  const savedResponse = currentPage.responses[responseOption.value]
  const saveResponse = (response: string) => saveQuestionResponse({
    question,
    responseIndex,
    questionIndex,
    response
  })

  if (error) {
    throw error
  }

  // The button consists of a background div with the configured background color
  // and another div in front of it with a black background and opacity of 0%-10%.
  // This is used to darken the button on hover, even if it is styled using raw
  // CSS colors (which makes it so we can't use tailwind's bg-opacity)
  // Note: The classes from responseOption are added for the demo phonebank (which uses tailwind classes instaed of raw color values)
  const button = (
    <div class={`rounded-md w-full shadow-sm border border-transparent leading-4 bg-gray-100 ${responseOption.bgClass || ''}`}
      key={`button ${contact.id} ${pageIndex} ${questionIndex} ${responseIndex}`}
      style={responseOption.backgroundColor && { backgroundColor: toRgbColor(responseOption.backgroundColor) }}>
      <div class={`${savedResponse ? 'bg-opacity-5' : 'bg-opacity-0'} bg-black hover:bg-opacity-10 rounded-md box-content p-4 md:p-3 font-medium text-sm text-gray-800 text-center ${responseOption.textClass || ''}`}
        style={responseOption.color && { color: toRgbColor(responseOption.color) }}>
        <ScriptText text={responseOption.label} contact={contact} firstName={user?.firstName} />
      </div>
    </div>
  )

  if (responseOption.type === 'sms' && contact?.phoneNumber) {
    const body = substituteValuesText(responseOption.body, contact, user?.firstName)

    // In Google Voice mode, copy the message text to the clipboard
    if (user?.googleVoice) {
      const saveAndCopyTextToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(body)
        } catch (err: any) {
          console.error('error copying to clipboard:', err)
          setError(err)
          return
        }
        saveResponse(responseOption.value)
        showNotification({
          title: 'Text Message Copied to Clipboard',
          text: 'Now, you can paste the message into Google Voice to send it to the contact',
          icon: <ClipboardCopyIcon className='h-6 w-6' />,
          timeout: 2000,
          key: contact.id
        })
      }

      return (
        <button onClick={saveAndCopyTextToClipboard}>
          {button}
        </button>
      )
    } else {
      // In non-Google-Voice mode, turn the button into an SMS link
      const href = `sms://${contact.phoneNumber};?&body=${encodeURIComponent(body)}`
      return (
        <a href={href} onClick={() => saveResponse(responseOption.value)}>
          {button}
        </a>
      )
    }
  } else {
    return (
      <button onClick={() => saveResponse(responseOption.value)}>
        {button}
      </button>
    )
  }
}

const ScriptInput: FunctionalComponent<{
  id: string,
  mode: PhonebankMode,
  responseOption: ResponseInput,
  question: ScriptQuestion,
  responseIndex: number,
  questionIndex: number,
}> = ({ id, mode, responseOption, question, responseIndex, questionIndex }) => {
  const { user } = useUser()
  const { currentPage, pageIndex, saveQuestionResponse } = useScriptState(id, mode, false)
  const { contact } = useCurrentContact(id, mode, false)

  if (!currentPage || !contact) {
    return null
  }

  const saveResponse = (response: string) => saveQuestionResponse({
    question,
    responseIndex,
    questionIndex,
    response
  })

  const label = substituteValuesText(responseOption.label, contact, user?.firstName)

  // Focus on the input box if this is the first question on the page
  const input = useRef(null as any)
  useEffect(() => {
    if (responseIndex === 0 && Object.keys(currentPage.responses).length === 0) {
      input && input.current && typeof input.current.focus === 'function' && input.current.focus()
    }
  }, [input, currentPage.responses])

  // If there is a goToPage configured for this, show a Save button and only
  // save the results when it is pressed.
  // Otherwise, save the value to the store on every input
  if (typeof responseOption.goToPage === 'string') {
    const [inputValue, setInputValue] = useState(currentPage.responses[question.title])
    const onInput = (e: any) => {
      setInputValue(e.target?.value)
    }
    const onSubmit = (e: Event) => {
      e.preventDefault()
      saveResponse(inputValue)
    }

    return (
      // TODO maybe save to db on every input event
      <form onSubmit={onSubmit} class='inline-flex gap-2 w-full'
        key={`input ${contact.id} ${pageIndex} ${questionIndex} ${responseIndex}`}>
        <input ref={input} type='text' placeholder={label} value={inputValue} onInput={onInput}
          class='shadow appearance-none border rounded py-2 px-3 focus:outline-none focus:ring-2 flex-grow' />
        <input type='submit' value='Save' class='rounded-md shadow-sm bg-blue-600 hover:bg-blue-900 hover:shadow-none text-white text-opacity-90 py-4 px-4 sm:py-2' />
      </form>
    )
  } else {
    // TODO should all input forms have the save button next to them?
    // TODO don't send an update on every keypress
    return (
      <input ref={input} type='text' placeholder={label} value={currentPage.responses[question.title]} onInput={(e: any) => saveResponse(e.target.value)}
        class='shadow appearance-none border rounded py-2 px-3 focus:outline-none focus:ring-2 flex-grow' />
    )
  }
}

const ScriptSection: FunctionalComponent<{
  id: string,
  mode: PhonebankMode,
  question: ScriptQuestion,
  questionIndex: number
}> = ({ id, mode, question, questionIndex }) => {
  let text: string | VNode[]
  let responses: JSX.Element[]
  const { user } = useUser()
  const { contact } = useCurrentContact(id, mode, false)
  const { currentPageScript } = useScriptState(id, mode, false)

  const scriptTextSection = useRef(null as any)
  useEffect(() => {
    scrollIntoView(scriptTextSection.current)
  }, [question, scriptTextSection])

  text = useMemo(() =>
    question.text.split(/\n/g)
      .map((paragraph) => (
        <div class='pb-2'>
          <ScriptText text={paragraph} contact={contact} firstName={user?.firstName} />
        </div>
      )),
    [question.text, contact?.id, user?.firstName])
  if (question.responses.length > 0) {
    responses = question.responses.map((responseOption, responseIndex) => (
      responseOption.type === 'input'
        ? <ScriptInput key={responseOption.label} {...{ id, mode, responseOption, question, responseIndex, questionIndex }} />
        : <ScriptButton key={responseOption.label} {...{ id, mode, responseOption, question, responseIndex, questionIndex }} />
    ))
  } else if (currentPageScript?.questions.length === questionIndex + 1) {
    // Only show the save button on the last question on the page
    responses = [(
      <SaveLoadNextButton id={id} mode={mode} />
    )]
  } else {
    responses = []
  }

  return (
    <div>
      <h3 class='text-xl text-center mb-10' ref={scriptTextSection}>{text}</h3>
      <div class='max-w-xs grid mx-auto gap-3 md:gap-4 mb-4'>
        {responses}
      </div>
    </div>
  )
}

const CallScript: FunctionalComponent<{
  id: string,
  mode: PhonebankMode,
  class: string
}> = ({ id, mode, class: className }) => {
  const { currentPage, currentPageScript, pageIndex, goBackToPreviousPage } = useScriptState(id, mode, false)

  if (!currentPage || !currentPageScript) {
    return null
  }

  return (
    <div class={className}>
      <div class='grid grid-cols-1 gap-3 md:gap-y-12 w-full'>
        {currentPageScript.questions.length > 0
          ? currentPageScript.questions.map((question, questionIndex) => (
            <ScriptSection {...{ id, mode, question, questionIndex }} />
          ))
          : <ScriptSection {...{ id, mode, question: { text: 'Thank you, have a nice day!', title: '', responses: [] }, questionIndex: 0 }} />}

        {// Add the back button
          pageIndex !== 0
            ? (
              <button class='m-4 md:m-3 text-center text-sm font-medium text-gray-500'
                onClick={goBackToPreviousPage}
                title='Undo last response'>
                &lt;&lt; Back
              </button>
            )
            : null}
      </div>
    </div>
  )
}

export default CallScript
