import { PhonebankMode } from '../lib/types'
import useUserPhonebanks from './useUserPhonebanks'

export default function usePhonebankStats(id: string, mode: PhonebankMode) {
  const { phonebanks, error, mutatePhonebank, mutateUserPhonebanks } = useUserPhonebanks(mode)
  const stats = phonebanks?.find((phonebank) => phonebank.id === id)

  return {
    stats,
    error,
    addCallCompleted: (phonebankId: string) => {
      // if the phonebank stats haven't been loaded and cached yet,
      // we don't need to add to them
      if (!phonebanks) {
        return
      }

      const phonebank = phonebanks.find(({ id }) => id === phonebankId)
      if (phonebank) {
        mutatePhonebank({
          id: phonebankId,
          userCalls: phonebank.userCalls + 1,
          completed: phonebank.completed + 1,
          userCallsThisWeek: phonebank.userCallsThisWeek + 1
          // Only revalidate in normal mode
        }, mode === PhonebankMode.NORMAL)
      } else {
        // if we haven't loaded the stats for this phonebank for some reason,
        // re-fetch the user's phonebank stats
        mutateUserPhonebanks()
      }
    }
  }
}
