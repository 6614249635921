import { h, FunctionalComponent } from 'preact'
import { useContext, useEffect, useErrorBoundary, useState } from 'preact/hooks'
import { Router, Route } from 'preact-router'
import AuthenticatedRoute from './authenticated-route'
import Header from './header'
import NotificationArea, { NotificationContext } from './notification-area'
import { attributes as getAckeeAttributes } from 'ackee-tracker'
import Redirect from '../components/redirect'
import useSupabase from '../hooks/useSupabase'
import useUser from '../hooks/useUser'
import { setUser as setSentryUser, captureException } from '@sentry/browser'
import useAckee from '../hooks/useAckee'

// Code-splitting is automated for `routes` directory
import Home from '../routes/home'
import NewPhonebank from '../routes/new'
import DemoPhonebank from '../routes/phonebanks/demo'
import TrainingPhonebank from '../routes/phonebanks/training'
import Phonebank from '../routes/phonebanks'
import Login from '../routes/login'
import Docs from '../routes/docs'
import PrivacyPolicy from '../routes/privacy-policy'
import Settings from '../routes/settings'
import Dashboard from '../routes/dashboard'
import PageNotFound from '../routes/page-not-found'

const Inner: FunctionalComponent = () => {
  // Note: this useContext call will only work properly if this whole component
  // is _inside_ the NotificationArea
  const { showError } = useContext(NotificationContext)
  useErrorBoundary((err: any) => {
    console.error(err)
    if (!err) {
      return
    }

    showError(err)

    if (err instanceof Error) {
      captureException(err)
    } else if (typeof err === 'string') {
      captureException(new Error(err))
    } else if (typeof err === 'object' && err.message) {
      captureException(new Error(err.message))
    }
  })

  const [url, setUrl] = useState(null as string | null)

  useEffect(() => { document.title = 'Turbo Phonebank' }, [])

  // Ackee analytics
  const ackee = useAckee()
  useEffect(() => {
    if (url) {
      return ackee?.record('6c83063c-cca6-404b-b21c-a02151881d3c', {
        ...getAckeeAttributes(true),
        siteLocation: new URL(url, window.location.href).toString()
      }).stop
    }
  }, [url])

  const onUrlChange = ({ url }: { url: string }) => {
    setUrl(url)

    // Scroll to the top of the window after navigating
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }

  // Handle auth state change
  const supabase = useSupabase()
  const { user, mutateUser } = useUser()
  useEffect(() => {
    mutateUser()
    const { data: subscription } = supabase.auth.onAuthStateChange((event, _session) => {
      console.log('auth state changed:', event)
      mutateUser()
    })
    return () => subscription?.unsubscribe()
  }, [])

  // Set user for Sentry reporting
  useEffect(() => {
    let username: string | undefined
    if (user?.firstName && user?.lastName) {
      username = `${user.firstName} ${user.lastName[0]}`
    }
    setSentryUser({
      id: user?.id,
      username
    })
  }, [user?.id])

  // This is added by AuthenticatedRoute if the user needs to login
  // or set their name before going to the desired page
  const redirect = typeof window !== 'undefined' && (new URL(window.location.href)).searchParams.get('redirect')

  return (
    <div id='app' class='text-gray-800 min-h-screen'>
      <Header />
      <div class='container p-2 sm:p-6 mx-auto'>
        <Router onChange={onUrlChange}>
          <Home path='/' />
          <Docs path='/docs/:rest*' />
          <Login path='/login' />
          <DemoPhonebank path='/demo' />
          <PrivacyPolicy path='/privacy-policy' />
          <AuthenticatedRoute path='/new' component={NewPhonebank} redirect={redirect} />
          <Redirect path='/phonebank/:rest*' replace={/\/phonebank\//g} with='/p/' />
          <Route path='/p/:phonebankId/training' component={TrainingPhonebank} />
          <AuthenticatedRoute path='/p/:phonebankId' component={Phonebank} redirect={redirect} />
          <AuthenticatedRoute path='/dashboard' component={Dashboard} />
          <AuthenticatedRoute path='/settings' component={Settings} redirect={redirect} />
          <Redirect path='/profile' to='/settings' />
          <PageNotFound default />
        </Router>
      </div>
    </div>
  )
}

// The contents of App are wrapped in the NotificationArea here so that the
// useContext call to get the showError function for the error boundary works
const App: FunctionalComponent = () => (
  <NotificationArea>
    <Inner />
  </NotificationArea>
)

export default App
