import { parseIdFromUrl } from '../lib/id'
import useSWR from 'swr'
import { PhonebankSettings, ContactSidebarSettings, Script, PhonebankMode } from '../lib/types'

const DEMO_SIDEBAR: ContactSidebarSettings = {
  rows: [{
    pattern: 'Note: this demo uses fake contact details.',
    classes: 'text-xs text-gray-500 mb-2'
  }, {
    pattern: '{{firstName}} {{lastName}} ({{gender}}, {{age}})',
    bold: true,
    fontSize: 18
  }, {
    pattern: '{{phoneNumber}}',
    bold: true,
    classes: 'text-blue-700'
  }, {
    pattern: '{{city}}, {{state}}'
  }, {
    pattern: ''
  }, {
    pattern: 'Polling Location',
    bold: true
  }, {
    pattern: '{{street}}'
  }]
}

const DEMO_SCRIPT: Script = {
  firstPage: 'Call Result',
  pages: {
    'Call Result': {
      questions: [{
        text: 'Hi, is this {{firstName}}?',
        title: 'Call Result',
        responses: [{
          type: 'button',
          value: 'Yes',
          label: 'Yes',
          textClass: 'text-green-900',
          bgClass: 'bg-green-50',
          goToPage: 'Voting'
        }, {
          type: 'button',
          value: 'Spanish',
          label: 'Español',
          textClass: 'text-blue-900',
          bgClass: 'bg-blue-50',
          goToPage: 'Voting - Spanish'
        }, {
          type: 'button',
          value: 'Chinese',
          label: '普通话',
          textClass: 'text-blue-900',
          bgClass: 'bg-blue-50',
          goToPage: 'Voting - Chinese'
        }, {
          type: 'sms',
          value: 'Not Home',
          label: 'Send Didn\'t Get Through Message',
          textClass: 'text-yellow-900',
          bgClass: 'bg-yellow-50',
          body: `Hi {{firstName}}, this is... I'm sorry I missed you!
    (you can customize the messages that will be sent in the script to include links, follow-up information, or any of the contact's details)`,
          goToPage: 'Next Contact'
        }, {
          type: 'button',
          value: 'Not Home',
          label: 'Not Home / Busy',
          textClass: 'text-yellow-900',
          bgClass: 'bg-yellow-50',
          goToPage: 'Next Contact'
        }, {
          type: 'button',
          value: 'Refused',
          label: 'Refused to Talk',
          textClass: 'text-red-900',
          bgClass: 'bg-red-50',
          goToPage: 'Next Contact'
        }, {
          type: 'button',
          value: 'Wrong Number',
          label: 'Wrong Number / Line Disconnected',
          textClass: 'text-red-900',
          bgClass: 'bg-red-50',
          goToPage: 'Next Contact'
        }]
      }]
    },
    'Voting': {
      questions: [{
        text: 'Hi, this is {{Caller Name}}. Can we count on you to vote?',
        title: 'Voting',
        responses: [{
          type: 'button',
          value: 'Yes',
          label: '🗳️ Yes! 🗳️',
          textClass: 'text-green-900',
          bgClass: 'bg-green-50',
          goToPage: 'Volunteering'
        }, {
          type: 'sms',
          value: 'Texted Polling Place',
          label: '📲 Send Polling Place',
          textClass: 'text-yellow-900',
          bgClass: 'bg-yellow-50',
          body: 'Hi {{firstName}}, good talking to you just now. Thank you for being a voter! Your polling location is at {{street}}',
          goToPage: 'Next Contact'
        }, {
          type: 'button',
          value: 'No',
          label: '😢 No',
          textClass: 'text-red-900',
          bgClass: 'bg-red-50',
          goToPage: 'Not Voting'
        }]
      }
      ]
    },
    'Voting - Spanish': {
      questions: [{
        text: 'Hola, soy {{Caller Name}}. ¿Podemos contar contigo para votar?',
        title: 'Voting - Spanish',
        responses: [{
          type: 'button',
          value: 'Yes',
          label: 'Si',
          textClass: 'text-green-900',
          bgClass: 'bg-green-50',
          goToPage: 'Next Contact'
        }, {
          type: 'button',
          value: 'No',
          label: 'No',
          textClass: 'text-red-900',
          bgClass: 'bg-red-50',
          goToPage: 'Next Contact'
        }]
      }]
    },
    'Voting - Chinese': {
      questions: [{
        text: '你好，我的名字是 ... 。 我们可以指望您投票吗？',
        title: 'Voting - Chinese',
        responses: [{
          type: 'button',
          value: 'Yes',
          label: '是',
          textClass: 'text-green-900',
          bgClass: 'bg-green-50',
          goToPage: 'Next Contact'
        }, {
          type: 'button',
          value: 'No',
          label: '没有',
          textClass: 'text-red-900',
          bgClass: 'bg-red-50',
          goToPage: 'Next Contact'
        }]
      }]
    },
    'Not Voting': {
      questions: [{
        text: 'I\'m sorry to hear that. Thanks for your time.',
        title: 'Not Voting',
        responses: []
      }]
    },
    'Volunteering': {
      questions: [{
        text: 'Excellent! Would you be interested in volunteering with us?',
        title: 'Volunteering',
        responses: [{
          type: 'button',
          value: 'Yes',
          label: 'Yes',
          goToPage: 'Email',
          textClass: 'text-green-900',
          bgClass: 'bg-green-50'
        }, {
          type: 'button',
          value: 'No',
          label: 'Not right now',
          goToPage: 'Next Contact',
          textClass: 'text-red-900',
          bgClass: 'bg-red-50'
        }]
      }]
    },
    'Email': {
      questions: [{
        text: 'Great! Can I have your email to send you more information?',
        title: 'Email',
        responses: [{
          type: 'input',
          label: 'Email',
          goToPage: 'Next Contact'
        }]
      }]
    }
  }
}

export default function usePhonebank(id: string, mode: PhonebankMode, revalidateOnMount: boolean) {
  id = parseIdFromUrl(id)
  const { data, error, mutate, isValidating } = useSWR(['phonebank', id, 'mode', mode], async () => {
    if (mode === PhonebankMode.DEMO) {
      return {
        id: 'DEMO',
        title: 'Demo Phonebank',
        script: DEMO_SCRIPT,
        sidebar: DEMO_SIDEBAR
      } as Pick<PhonebankSettings, 'id' | 'title' | 'script' | 'sidebar'>
    }
    const res = await fetch(`/api/phonebanks/${id}`)
    if (res.ok) {
      const data = await res.json()
      return data as Pick<PhonebankSettings, 'id' | 'title' | 'script' | 'sidebar'>
    } else if (res.status === 404) {
      return
    } else {
      throw new Error(`Error loading phonebank: ${await res.text()}`)
    }
  }, {
    revalidateOnFocus: false,
    revalidateOnMount
  })

  return {
    title: data?.title,
    script: data?.script,
    sidebar: data?.sidebar,
    phonebankLoaded: !!data,
    phonebankEndedOrNonexistent: !isValidating && !data,
    error,
    mutatePhonebank: mutate
  }
}
