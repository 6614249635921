import { PhonebankMode, ScriptState, ScriptQuestion } from '../lib/types'
import useCurrentContact from './useCurrentContact'
import useSupabase from './useSupabase'
import useSWR from 'swr'
import usePhonebank from './usePhonebank'
import useUser from './useUser'
import usePhonebankStats from './usePhonebankStats'
import useAckee from './useAckee'

export default function useScriptState(id: string, mode: PhonebankMode, revalidateOnMount: boolean) {
  const supabase = useSupabase()
  const ackee = useAckee()
  const { user } = useUser()
  const { contact, mutateCurrentContact } = useCurrentContact(id, mode, revalidateOnMount)
  const { script, phonebankLoaded } = usePhonebank(id, mode, revalidateOnMount)
  const { addCallCompleted } = usePhonebankStats(id, mode)

  const defaultState: ScriptState | undefined = script ? [{
    pageTitle: script.firstPage,
    responses: {}
  }] : undefined

  const key = () => phonebankLoaded ? ['phonebank', id, 'mode', mode, 'contact', contact!.id, 'scriptState'] : null
  const { data: scriptState, error, mutate: mutateScriptState } = useSWR(key, async () => {
    // In the demo and training modes, we just use a local script state
    if (mode !== PhonebankMode.NORMAL) {
      return defaultState
    }

    const { data, error } = await supabase.from<{ responses: ScriptState }>('results')
      .select('responses')
      .match({
        phonebank: id,
        id: contact!.id
      })
      .single()
    if (error) {
      console.error('error loading script state:', error)
      throw new Error(`Error loading script state (${error.code}: ${error.message})`)
    }
    return data?.responses && data.responses.length > 0
      ? data.responses
      : defaultState
  }, {
    // For the test and demo modes, this uses the shared state trick described in
    // https://paco.sh/blog/shared-hook-state-with-swr
    fallbackData: mode === PhonebankMode.NORMAL ? undefined : defaultState,
    revalidateOnMount: mode === PhonebankMode.NORMAL && revalidateOnMount,
    revalidateOnFocus: mode === PhonebankMode.NORMAL,
    revalidateOnReconnect: mode === PhonebankMode.NORMAL
  })
  const currentPage = scriptState && scriptState[scriptState.length - 1]
  const currentPageScript = script && currentPage && script.pages[currentPage.pageTitle]

  // Mutate the current state and, in normal mode, save it to the DB
  const updateScriptState = async (newScriptState: ScriptState) => {
    mutateScriptState(newScriptState, false)

    if (mode === PhonebankMode.NORMAL) {
      const { error } = await supabase.from('results')
        .update({
          responses: newScriptState
        }, { returning: 'minimal' })
        .match({
          phonebank: id,
          id: contact!.id
        })
      if (error) {
        mutateScriptState(undefined, true)
        console.error('error saving script state:', error)
        throw new Error(`Error saving script state (${error.code}: ${error.message})`)
      }
    }
  }

  // Save the current script state and load the next contact
  const saveResponsesAndLoadNextContact = async (responses = scriptState) => {
    mutateCurrentContact(undefined, false)
    if (mode === PhonebankMode.NORMAL) {
      ackee?.action('a5c4491b-217d-42f4-b255-bae41c8379d9', {
        key: 'Save Responses',
        value: 1
      })
      const { data, error } = await supabase.rpc('save_results_and_load_next_contact', {
        phonebank_id: id,
        user_id: user!.id,
        contact_id: contact!.id,
        responses
      }).single()
      if (error) {
        console.error('error saving responses and loading contact:', error)
        throw new Error(`Error saving responses and loading next contact (${error.code}: ${error.message})`)
      }

      mutateCurrentContact({
        contact: typeof data === 'string' ? JSON.parse(data) : data,
        phonebankComplete: !data
      }, false)
    } else {
      mutateCurrentContact()
      mutateScriptState()
    }

    // Update stats
    addCallCompleted(id)
  }

  // Update the script state based on a single question answer
  // and determine whether to just save that to the DB or
  // save all the responses and load the next contact
  const saveQuestionResponse = async ({
    question,
    questionIndex,
    responseIndex,
    response
  }: {
    question: ScriptQuestion,
    questionIndex: number,
    responseIndex: number,
    response: string
  }) => {
    let nextPageTitle = question.responses[responseIndex].goToPage

    // If the next page doesn't actually exist, save the responses and load the next contact
    if (nextPageTitle && !script!.pages[nextPageTitle]) {
      nextPageTitle = undefined
    }

    // Add response to current page responses
    const newScriptState: ScriptState = [...scriptState!.slice(0, -1), {
      pageTitle: currentPage!.pageTitle,
      responses: {
        ...currentPage!.responses,
        [question.title]: response
      }
    },]
    // If we're going to the next page, add another entry for the new page
    if (nextPageTitle) {
      newScriptState.push({
        pageTitle: nextPageTitle,
        responses: {}
      })
    }

    // If there is another question on the page or another page, just save the results and move on
    if (nextPageTitle || questionIndex < currentPageScript!.questions.length - 1) {
      return updateScriptState(newScriptState)
    } else {
      return saveResponsesAndLoadNextContact(newScriptState)
    }
  }

  return {
    currentPage,
    currentPageScript,
    pageIndex: scriptState && scriptState.length - 1,
    error,
    // this indicates whether the user has started filling out the script for the given contact
    hasQuestionResponses: scriptState && (scriptState.length > 1 || Object.getOwnPropertyNames(scriptState[0].responses).length > 0),
    saveResponsesAndLoadNextContact: () => saveResponsesAndLoadNextContact(),
    // note: this will not clear the responses on the previous page
    // this means that hasQuestionResponses will be true even if we're back to the first page with only one question on it
    goBackToPreviousPage: () => updateScriptState(scriptState!.slice(0, -1)),
    saveQuestionResponse
  }
}
