import { createClient, SupabaseClient } from '@supabase/supabase-js'

// TODO is there a "better" way to have a singleton?
let supabase: SupabaseClient

export default function useSupabase(): SupabaseClient {
  if (!supabase && typeof window !== 'undefined') {
    supabase = createClient(process.env.PREACT_APP_SUPABASE_URL!,
      process.env.PREACT_APP_SUPABASE_PUBLIC_KEY!)
  }
  return supabase
}
