const SCROLL_OFFSET = 25

// Only completely visible elements return true:
function isScrolledIntoView(el: HTMLElement) {
    const rect = el.getBoundingClientRect()
    return (rect.top >= 0) && (rect.bottom <= window.innerHeight)
}

/// Scroll to the element if it's not already in view
export function scrollIntoView(element: HTMLElement | null): void {
    if (!element || typeof window === 'undefined' || isScrolledIntoView(element)) {
        return
    }

    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = element.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - SCROLL_OFFSET

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
    })
}
