import useSWR from 'swr'
import { SetRequired } from 'type-fest'
import { PhonebankMode } from '../lib/types'
import useSupabase from './useSupabase'
import useUser from './useUser'

export interface PhonebankDetails {
  id: string,
  title: string,
  isActive: boolean,
  lastAccessed?: Date,
  totalContacts: number,
  spreadsheetId?: string
  inProgress: number,
  completed: number,
  userCalls: number,
  userCallsThisWeek: number,
  contactsLeft: number
}

export default function useUserPhonebanks(mode: PhonebankMode) {
  const supabase = useSupabase()
  const { user } = useUser()

  // In normal mode, only load the phonebanks if the user is set
  // In demo and training modes, load the fake phonebanks no matter what
  const key = () => mode === PhonebankMode.NORMAL
    ? ['user', user!.id, 'phonebanks', 'mode', mode]
    : ['user', 'phonebanks', 'mode', mode]

  const { data, error, mutate } = useSWR(key, async (): Promise<PhonebankDetails[]> => {
    if (mode !== PhonebankMode.NORMAL) {
      return []
    }

    const { data, error } = await supabase.rpc('load_user_phonebank_stats')
    if (error) {
      throw error
    }
    return data!.map((row) => {
      let lastAccessed: Date | undefined
      try {
        lastAccessed = new Date(row.last_accessed)
      } catch (err) {
        console.warn('Invalid date:', row.last_accessed, err)
      }
      const totalContacts = row.total_contacts || 0
      const inProgress = row.in_progress || 0
      const completed = row.completed || 0
      const userCalls = row.user_calls || 0
      const userCallsThisWeek = row.user_calls_this_week || 0
      const contactsLeft = Math.max(totalContacts - completed - inProgress + (row.assigned_to_user || 0), 0)
      return {
        id: row.id,
        title: row.title,
        isActive: row.is_active,
        lastAccessed,
        totalContacts,
        spreadsheetId: row.spreadsheet_id,
        inProgress,
        completed,
        userCalls,
        userCallsThisWeek,
        contactsLeft
      }
    })
  }, {
    revalidateOnReconnect: mode === PhonebankMode.NORMAL,
    revalidateOnFocus: mode === PhonebankMode.NORMAL,
    revalidateOnMount: mode === PhonebankMode.NORMAL
  })

  return {
    phonebanks: data,
    error,
    mutateUserPhonebanks: mutate,
    // Function to mutate a specific phonebank
    mutatePhonebank: (update: SetRequired<Partial<PhonebankDetails>, 'id'>, shouldRevalidate = false) => mutate(data?.map((phonebank) => {
      if (update.id === phonebank.id) {
        const newPhonebank: Partial<PhonebankDetails> = {}
        let key: keyof PhonebankDetails
        for (key in phonebank) {
          newPhonebank[key] = update[key] || phonebank[key] as any
        }
        return newPhonebank as PhonebankDetails
      } else {
        // TODO should we clone this value?
        return phonebank
      }
    }), shouldRevalidate)
  }
}
