import { h, FunctionalComponent, Fragment, VNode, ComponentChildren } from 'preact'
import { Contact } from '../../lib/types'

const Bold: FunctionalComponent<{ children?: ComponentChildren }> = ({ children }) => (
  <b class='font-bold'>{children}</b>
)

const ScriptText: FunctionalComponent<{ text: string, contact?: Contact, firstName?: string }> = ({ text, contact, firstName }) => {
  const elements: VNode<any>[] = []

  let substr = text
  substitutionLoop:
  while (substr.length > 0) {
    const nextSubstitution = substr.indexOf('{{')
    if (nextSubstitution === -1) {
      elements.push((
        <>{substr}</>
      ))
      break
    }

    // Add the previous characters
    elements.push((
      <>{substr.slice(0, nextSubstitution)}</>
    ))
    substr = substr.slice(nextSubstitution + 2)

    // Substitute user name
    const lowerCase = substr.substr(0, 13).toLowerCase()
    if (firstName) {
      if (lowerCase.startsWith('your name}}') || lowerCase.startsWith('user name}}')) {
        elements.push((
          <Bold>{firstName}</Bold>
        ))
        substr = substr.slice(11)
        continue
      } else if (lowerCase.startsWith('caller name}}')) {
        elements.push((
          <Bold>{firstName}</Bold>
        ))
        substr = substr.slice(13)
        continue
      }
    }

    // Contact value substitution
    for (const field in contact?.additionalFields || {}) {
      if (substr.startsWith(`${field}}}`)) {
        elements.push((
          <Bold>{contact!.additionalFields![field]}</Bold>
        ))
        substr = substr.slice(field.length + 2)
        continue substitutionLoop
      }
    }

    // No substitution was found so the opening curly braces
    // just need to be added back as strings
    elements.push((
      <>{'{{'}</>
    ))
  }

  // This centers lines that don't fill the whole horizontal space
  // while left-justifying lines that are longer
  return <div class='flex place-items-center'>
    <p class='text-left mx-auto'>
      {elements}
    </p>
  </div>
}

export default ScriptText
