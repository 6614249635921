import { h, FunctionalComponent } from 'preact'
import useUser from '../hooks/useUser'

const Avatar: FunctionalComponent = () => {
  const { user } = useUser()
  if (!user) {
    return null
  }

  const initials = user.firstName
    ? `${user.firstName} ${user.lastName}`
      .split(/[- ]/g)
      .map((part) => part[0])
      .slice(0, 3)
      .join('')
      .toUpperCase()
    : user.email[0].toUpperCase()

  return (
    <span class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-indigo-500">
      <span class="text-sm font-medium leading-none text-white">{initials}</span>
    </span>
  )
}

export default Avatar
