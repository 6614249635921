import { h, Fragment, FunctionalComponent } from 'preact'
import { Link, route } from 'preact-router'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import useUser from '../hooks/useUser'
import useSupabase from '../hooks/useSupabase'
import LogoAndName from './logo-and-name'
import Avatar from './avatar'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const NavLink: FunctionalComponent<{
  href: string,
  native?: boolean,
  class: string,
  activeClassName: string,
  target?: string,
  rel?: string
}> = ({
  href,
  children,
  native = false,
  ...props
}) =>
    native ?
      <a href={href} {...props}>
        {children}
      </a>
      : <Link href={href} {...props}>
        {children}
      </Link>

const Header: FunctionalComponent = () => {
  const { user } = useUser()
  const supabase = useSupabase()

  const navLinks = [{
    href: '/new',
    text: 'Create Phonebank'
  }, {
    href: '/docs',
    text: 'Documentation',
  }, {
    // TODO this needs to be handled separately
    href: 'https://join.slack.com/t/turbophonebank/shared_invite/zt-rlj80ysm-ZAiEnggzd8o3wdYrLrdwWg',
    text: 'Community',
    rel: 'noopener',
    target: '_blank',
    native: true
  }]

  const userMenuLinks = [{
    href: '/dashboard',
    text: 'Dashboard'
  }, {
    href: '/settings',
    text: 'Settings'
  },
  {
    href: '#',
    text: 'Sign Out',
    onClick: () => {
      supabase.auth.signOut()
      route('/')
    }
  }]

  return (
    <Disclosure as='nav' className='bg-white shadow'>
      {({ open }: { open: boolean }) => (
        <>
          <div className='mx-auto px-4 lg:px-6 xl:px-8'>
            <div className='flex justify-between h-16'>
              <div className='flex'>
                <div className='flex-shrink-0 flex items-center'>
                  <LogoAndName />
                </div>
                <div className='hidden lg:ml-6 lg:flex lg:space-x-8'>
                  {navLinks.map(({ text, ...props }) => (
                    <NavLink
                      activeClassName='border-indigo-500 text-gray-900'
                      class='border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                      {...props}
                    >
                      {text}
                    </NavLink>
                  ))}
                </div>
              </div>

              {/* User avatar on wide layouts */}
              <div className='hidden lg:ml-6 lg:flex lg:items-center'>
                {/* Notifications */}
                {/* <button className='bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                  <span className='sr-only'>View notifications</span>
                  <BellIcon className='h-6 w-6' aria-hidden='true' />
                </button> */}

                {!user
                  ? (
                    // Not logged-in
                    <Link
                      href='/login'
                      activeClassName='border-indigo-500 text-gray-900'
                      className='text-gray-500 hover:text-gray-700 inline-flex items-center px-1 pt-1 text-sm font-medium'
                    >
                      Login
                    </Link>
                  )
                  : (
                    // Profile drop-down menu when logged in
                    <Menu as='div' className='ml-3 relative'>
                      <div>
                        <Menu.Button className='bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                          <span className='sr-only'>Open user menu</span>
                          <Avatar />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='origin-top-right absolute right-0 mt-2 min-w-48 max-w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>

                          <div class='block px-4 py-2'>
                            <div class='text-sm text-gray-700 font-medium overflow-hidden overflow-ellipsis'>
                              {
                                user.firstName
                                  ? `${user.firstName} ${user.lastName}`
                                  : user.email
                              }
                            </div>
                            {user.firstName
                              ?
                              <div class='text-xs text-gray-500 overflow-hidden overflow-ellipsis'>
                                {user.email}
                              </div>
                              : null
                            }
                          </div>
                          <hr />
                          {userMenuLinks.map(({ href, text, onClick }) => (
                            <Menu.Item>
                              {({ active }: { active: boolean }) => (
                                <a
                                  href={href}
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                  onClick={onClick}
                                >
                                  {text}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )
                }

              </div>
              <div className='-mr-2 flex items-center lg:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* Mobile layout menu */}
          <Disclosure.Panel className='lg:hidden'>
            <div className='pt-2 pb-3 space-y-1'>
              {navLinks.map(({ text, ...props }) => (
                <NavLink
                  activeClassName='bg-indigo-50 border-indigo-500 text-indigo-700'
                  class='border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                  {...props}
                >
                  {text}
                </NavLink>
              ))}
            </div>
            {!user
              ? (
                // not logged in
                <Link
                  href='/login'
                  activeClassName='bg-indigo-50 border-indigo-500 text-indigo-700'
                  className='border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                >
                  Login
                </Link>
              )
              : (
                // logged in
                <div className='pt-4 pb-3 border-t border-gray-200'>
                  <div className='flex items-center px-4'>
                    <div className='flex-shrink-0'>
                      <Avatar />
                    </div>
                    <div className='ml-3'>
                      <div className='text-base font-medium text-gray-800'>{user.firstName} {user.lastName}</div>
                      <div className='text-sm font-medium text-gray-500'>{user.email}</div>
                    </div>
                    {/* <button className='ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                  <span className='sr-only'>View notifications</span>
                  <BellIcon className='h-6 w-6' aria-hidden='true' />
                </button> */}
                  </div>
                  <div className='mt-3 space-y-1'>
                    {userMenuLinks.map(({ href, text, onClick }) => (
                      <a
                        href={href}
                        className='block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100'
                        onClick={onClick}
                      >
                        {text}
                      </a>
                    ))}
                  </div>
                </div>
              )
            }
          </Disclosure.Panel>
        </>
      )
      }
    </Disclosure>
  )
}

export default Header
