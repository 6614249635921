import randomBytes from 'randombytes'

const NON_CHARACTER_REGEX = /[^\w\d]+/gu
const ID_REGEX = /[0-9a-f]{32}$/

export function createId(): string {
  return randomBytes(16).toString('hex')
}

export function titleAndIdToUrlPart(title: string, id: string): string {
  if (ID_REGEX.test(id)) {
    const titlePortion = title.replace(NON_CHARACTER_REGEX, '-')
      .slice(0, 50)
      .toLowerCase()
    return `${titlePortion}-${id}`
  } else {
    return id
  }
}

export function parseIdFromUrl(urlPart: string): string {
  if (ID_REGEX.test(urlPart)) {
    return ID_REGEX.exec(urlPart)![0]
  } else {
    return urlPart
  }
}
