import { h, FunctionalComponent, Fragment } from 'preact'
import useSupabase from '../../hooks/useSupabase'
import { useState, useEffect, useRef } from 'preact/hooks'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import QrCode from 'qrcode.react'

// This component displays a QR code the user can scan with their phone to quickly login.
// It creates and uses a magic link as if the user were going to sign in via email.
const LoginQrModal: FunctionalComponent<{ onDismiss?: () => void }> = ({ onDismiss }) => {
  const supabase = useSupabase()

  const [loginLink, setLoginLink] = useState('')
  const [open, setOpen] = useState(true)
  const div = useRef(undefined as HTMLDivElement | undefined)
  const qrSize = div?.current?.clientWidth
    || typeof window !== 'undefined' && Math.min(window.innerWidth - 40, 300)
    || 300
  const loggedIn = !!supabase.auth.user()

  // If the user is logged in, generate a login link to use for the QR code
  if (loggedIn) {
    useEffect((async () => {
      const redirect = window.location.href
      const res = await fetch('/api/users/loginLink', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          access_token: supabase.auth.session()?.access_token,
          redirect_to: redirect
        })
      })
      const body = await res.json()
      setLoginLink(body.login_link)
    }) as () => void, [])
  } else if (typeof window !== 'undefined') {
    // If they aren't logged in, just direct them to the same page
    setLoginLink(window.location.href)
  }

  // Also remove this entire element so clicking on the button
  // to display it will actually show the modal again
  if (onDismiss) {
    useEffect(() => {
      if (!open) {
        const timeout = setTimeout(onDismiss, 300)
        return () => clearTimeout(timeout)
      }
    }, [open])
  }

  if (!loginLink) {
    return null
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' static className='fixed z-10 inset-0 overflow-y-auto' open={open} onClose={setOpen}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6' ref={div}>
              <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                <button
                  type='button'
                  className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={() => setOpen(false)}
                >
                  <span className='sr-only'>Close</span>
                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>
              <div className='sm:flex sm:items-center'>
                {/* <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                  <ExclamationIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
                </div> */}
                <div className='mt-3 text-center sm:mt-0'>
                  <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                    Scan QR Code to Login
                  </Dialog.Title>
                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      Scan this with your camera app or QR code scanner to login on your phone. Then, you can place calls by tapping the contact's phone number.
                    </p>
                  </div>
                  <div class='flex items-center my-8'>
                    <a href={loginLink} class='mx-auto'>
                      <QrCode value={loginLink} size={qrSize} />
                    </a>
                  </div>
                  <div className='mt-2'>
                    {
                      loggedIn
                        ? <p className='text-sm text-gray-500'>
                          Note: this QR code will let anyone sign in as you (so you probably shouldn't show it to anyone else).
                        </p>
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default LoginQrModal
