import { h, FunctionalComponent } from 'preact'
import { Route, route } from 'preact-router'
import useUser from '../hooks/useUser'

const AuthenticatedRoute: FunctionalComponent<any> = (props: any) => {
  const { user, loading } = useUser()

  const redirectPath = (new URL(props.url, 'https://example.com')).pathname
  const redirect = props.url ? `?redirect=${redirectPath}` : ''

  // Don't show the login screen until we've finished loading the user (or not)
  if (loading && !user) {
    return null
  }

  // Redirect to login if not logged in already
  if (!user) {
    console.log('not logged in, redirecting to /login')
    route(`/login${redirectPath !== '/login' ? redirect : ''}`, true)
    return null
  } else if (props.path !== '/settings' && (!user.firstName || !user.lastName)) {
    // Redirect to profile page if no name is set
    console.log('user name is not set, redirecting to /settings')
    route(`/settings${redirectPath !== '/settings' ? redirect : ''}`, true)
    return null
  }

  return (
    <Route {...props} />
  )
}

export default AuthenticatedRoute
