import { h, FunctionalComponent, VNode, createContext } from 'preact'
import { useState, useEffect, useMemo, useCallback } from 'preact/hooks'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { NotificationDetails } from '../lib/types'
import Notification from './async/notification'

const NotificationContext = createContext({
  showNotification: (notification: NotificationDetails) => console.error('Cannot show notification outside of Notification Area', notification),
  showError: (err: any) => console.error('Cannot show error outside of Notification Area', err)
})

const NotificationArea: FunctionalComponent = ({ children }) => {
  const [notifications, setNotifications] = useState([] as VNode[])

  // Filter out hidden notifications
  // TODO make the effect smoother when notifications are stacked and one disappears
  useEffect(() => {
    const nonNull = notifications.filter(Boolean)
    if (nonNull.length !== notifications.length) {
      setNotifications(nonNull)
    }
  }, [notifications])

  const showNotification = useCallback((notification: NotificationDetails) => {
    setNotifications([...notifications, <Notification {...notification} />])
  }, [notifications])

  const showError = useCallback((error: Error | { message: string } | string, onDismiss?: () => void) => showNotification({
    title: 'Uh oh, there was an error :(',
    text: typeof error === 'string' ? error : error.message,
    icon: <ExclamationCircleIcon className='h-6 w-6 text-red-600' aria-hidden='true' />,
    onDismiss,
    timeout: 10000
  }), [notifications])

  // This memoization trick was copied from https://github.com/preactjs/preact/issues/2715
  const context = useMemo(() => ({ showNotification, showError }), [showNotification, showError])

  return (
    <NotificationContext.Provider value={context}>
      {children}

      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live='assertive'
        class='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
      >
        <div class='w-full flex flex-col items-center space-y-4'>
          {notifications}
        </div>
      </div>
    </NotificationContext.Provider>
  )
}

export default NotificationArea
export { NotificationContext }
