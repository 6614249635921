import useSWR from 'swr'
import { User } from '../lib/types'
import useSupabase from './useSupabase'

export default function useUser() {
  const supabase = useSupabase()

  const { data, error, mutate, isValidating } = useSWR('user', async (): Promise<User | undefined> => {
    const userId = supabase.auth.user()?.id
    if (!userId) {
      return undefined
    }

    const { data, error } = await supabase.from('users')
      .select('*')
      .eq('id', userId)
      .single()
    if (error) {
      throw error
    }
    const user = {
      id: data!.id,
      email: data!.email,
      firstName: data!.first_name,
      lastName: data!.last_name,
      googleVoice: data!.google_voice
    }
    return user
  })

  return {
    user: data,
    error,
    mutateUser: mutate,
    loading: isValidating
  }
}
