import { h, FunctionalComponent } from 'preact'
import usePhonebankStats from '../../hooks/usePhonebankStats'
import { PhonebankMode } from '../../lib/types'

const PhonebankStatsPanel: FunctionalComponent<{ id: string, mode: PhonebankMode, class: string }> = ({ id, mode, class: className }) => {
  const { stats, error } = usePhonebankStats(id, mode)
  if (error) {
    throw error
  }
  if (!stats) {
    return null
  }
  return (
    <div class={className}>
      <div class='text-center text-gray-700'>
        <h2 class='text-xl font-semibold pb-2'
          title={`You've made ${stats.userCalls} call${stats.userCalls !== 1 ? 's' : ''} in this phonebank${stats.userCalls > 0 ? '. Keep up the great work!' : ''}`}>
          {stats.userCalls || 0} Call{stats.userCalls !== 1 ? 's' : ''}
        </h2>
        <div>{stats.completed} / {stats.totalContacts} Dialed</div>
        <div>{mode === PhonebankMode.NORMAL ? stats.inProgress : 1} In Progress</div>
      </div>
    </div>
  )
}

export default PhonebankStatsPanel