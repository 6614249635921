import { create } from 'ackee-tracker'
import useSWR from 'swr'

export default function useAckee() {
  const { data } = useSWR(typeof window !== 'undefined' ? 'ackee' : null, () => {
    return create('https://analytics.turbovpb.com', {
      ignoreOwnVisits: true,
      detailed: true
    })
  })
  return data
}
