import { h, FunctionalComponent, Fragment } from 'preact'
import { useEffect } from 'preact/hooks'
import PhonebankComponent from '../../components/phonebank'
import Alert from '../../components/alert'
import { PhonebankMode } from '../../lib/types'
import { configureScope as configureSentryScope } from '@sentry/browser'
import useUserPhonebanks from '../../hooks/useUserPhonebanks'

const TrainingPhonebank: FunctionalComponent<{ phonebankId: string }> = ({ phonebankId }) => {
  configureSentryScope((scope) => scope.setTransactionName('Phonebank - Training'))
  const { mutateUserPhonebanks } = useUserPhonebanks(PhonebankMode.TRAINING)

  // Restart the stats
  useEffect(() => {
    mutateUserPhonebanks([{
      id: phonebankId,
      title: 'Training Phonebank',
      isActive: true,
      totalContacts: 100,
      contactsLeft: 100,
      inProgress: 1,
      userCalls: 0,
      userCallsThisWeek: 0,
      completed: 0
    }], false)
  }, [])


  return (
    <>
      <Alert type='WARNING' title='Training Mode' description='Contact details are fake and call results will not be saved.' disableDismiss={true} />
      <PhonebankComponent id={phonebankId} mode={PhonebankMode.TRAINING} />
    </>
  )
}

export default TrainingPhonebank
