import { h, FunctionalComponent, Fragment } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { PhonebankMode } from '../../lib/types'
import ContactSidebar from './contact-sidebar'
import CallScript from './call-script'
import Spinner from '../spinner'
import Alert from '../alert'
import PhonebankStatsPanel from './stats-panel'
import Feedback from '../feedback'
import FallingConfetti from '../async/falling-confetti'
import Fireworks from '../async/fireworks'
import LoginQrModal from './login-qr-modal'
import useCurrentContact from '../../hooks/useCurrentContact'
import usePhonebank from '../../hooks/usePhonebank'
import useScriptState from '../../hooks/useScriptState'
import { setTags as setSentryTags } from '@sentry/browser'
import usePhonebankStats from '../../hooks/usePhonebankStats'
import useAckee from '../../hooks/useAckee'
import useUser from '../../hooks/useUser'
import SkipContactButton from './skip-contact-button'
import FinishCallingButton from './finish-calling-button'
import NotFound from '../async/not-found'
import { parseIdFromUrl, titleAndIdToUrlPart } from '../../../src/lib/id'
import { route } from 'preact-router'

const LOADING_TIMEOUT = 5000

const PhonebankComplete: FunctionalComponent = () => (
  <div class='text-center mx-auto text-gray-800'>
    <h3 class='my-8 text-3xl md:text-6xl font-bold'>Phonebank Complete!</h3>
    <p class='md:text-lg max-w-lg mx-auto'>
      Excellent work. If you'd like to keep calling, please ask the
      phonebank organizer to add more contacts to the phonebank.
    </p>
  </div>
)

const Phonebank: FunctionalComponent<{ id: string, mode: PhonebankMode }> = ({ id, mode }) => {
  const idInPath = id
  id = parseIdFromUrl(id)

  // Set tags for Sentry reporting
  useEffect(() => setSentryTags({
    phonebank: id,
    phonebank_mode: mode
  }), [])

  const { phonebankLoaded, phonebankEndedOrNonexistent, title, error: loadPhonebankError } = usePhonebank(id, mode, true)
  const { user } = useUser()
  const { contact, error: loadContactError, mutateCurrentContact, phonebankComplete } = useCurrentContact(id, mode, true)
  const { currentPage, error: loadScriptStateError } = useScriptState(id, mode, true)
  const { stats, error: loadPhonebankStatsError } = usePhonebankStats(id, mode)
  const userCalls = stats?.userCalls
  const [userCallsWhenPageLoaded, setUserCallsWhenPageLoaded] = useState(userCalls)
  const ackee = useAckee()
  const finishedLoading = phonebankLoaded && contact && currentPage
  const [showLoginQrModal, setShowLoginQrModal] = useState(false)

  const error = loadPhonebankError || loadContactError || loadScriptStateError || loadPhonebankStatsError
  if (error) {
    throw error
  }

  // Show an error if the phonebank takes too long to load
  const [loadingTimeout, setLoadingTimeout] = useState(undefined as NodeJS.Timeout | undefined)
  useEffect(() => {
    // Clear the timeout if everything has loaded
    if (finishedLoading) {
      if (loadingTimeout) {
        clearTimeout(loadingTimeout)
      }
    } else if (!loadingTimeout) {
      // Set the timeout if the phonebank hasn't loaded yet
      const timeout = setTimeout(() => {
        console.log(`phonebank loaded: ${phonebankLoaded}, contact loaded: ${!!contact}, script state loaded: ${!!currentPage}`)
        throw new Error('The contact is taking unusually long to load. We\'ll look into what is going wrong but in the meantime, please try reloading the page.')
      }, LOADING_TIMEOUT)
      setLoadingTimeout(timeout)
      return () => clearTimeout(timeout)
    }
  }, [finishedLoading])

  // Force loading the first contact for the demo and training modes
  // because the SWR hook is not set to automatically revalidate
  if (mode !== PhonebankMode.NORMAL) {
    useEffect(() => {
      mutateCurrentContact(undefined, true)
    }, [id, mode])
  }

  // Set title
  useEffect(() => {
    if (title) {
      document.title = `${title}${mode === PhonebankMode.TRAINING ? ' (Training)' : ''} | Turbo Phonebank`

      // If the ID in the path doesn't match what it should be, update the URL
      // (this can happen if the phonebank's title was updated)
      const computedIdPart = titleAndIdToUrlPart(title, id)
      if (idInPath !== computedIdPart) {
        if (typeof window !== undefined) {
          const path = window.location.pathname.replace(idInPath, computedIdPart)
          console.log('title was updated, redirecting to:', path)
          route(path, true)
        }
      }
    }


  }, [title])

  if (phonebankEndedOrNonexistent) {
    return (
      <NotFound title='Phonebank not found' subtitle='Sorry, that phonebank does not exist or already ended.' />
    )
  }

  if (phonebankLoaded && phonebankComplete) {
    return (
      <>
        <Fireworks count={12} />
        <PhonebankComplete />
        <Feedback question='How was the phonebank experience?' class='mt-20 md:mt-36' />
      </>
    )
  }

  // Track contacts loaded
  useEffect(() => {
    if (contact) {
      let platform: string
      if (typeof window === 'undefined') {
        platform = 'unknown'
      } else if (window.innerWidth < 1024) {
        platform = 'mobile'
      } else {
        platform = 'desktop'
      }
      ackee?.action('46361517-a3c9-4822-a877-9472f794ae71', {
        key: mode,
        value: 1
      })
      ackee?.action('ce7ba7be-811c-44e9-8730-d92b2f50747a', {
        key: `${mode} on ${platform}`,
        value: 1
      })
    }
  }, [contact?.id])

  // Don't show the confetti again if you just reload the page
  if (typeof userCalls !== 'undefined' && typeof userCallsWhenPageLoaded === 'undefined') {
    setUserCallsWhenPageLoaded(userCalls)
  }

  return (
    <div>
      <div class='mx-auto  max-w-5xl lg:mt-6'>
        {// Show confetti after the first call and every 10
          userCalls
            && userCalls !== userCallsWhenPageLoaded
            && (userCalls === 1 || userCalls % 10 === 0)
            ? <FallingConfetti userCalls={userCalls} />
            : null}
        {showLoginQrModal ? <LoginQrModal onDismiss={() => setShowLoginQrModal(false)} /> : null}
        {finishedLoading
          ? <>
            {// Note: for some reason, putting this ternary statement above the confettiCount one
              // caused a "vnode is null" error. No idea what that's about.
              !(user?.googleVoice)
                ? <Alert type={'INFO'} title='Login on Mobile'
                  description="Click here to quickly login on your phone using a QR code."
                  onClick={() => setShowLoginQrModal(true)}
                  class='hidden md:block mb-10 -mt-2'
                />
                : null}
            <div class='flex lg:flex-col flex-wrap gap-10 lg:h-screen'>
              {
                /**
                 * On mobile, the order is:
                 * 1. Contact Sidebar
                 * 2. Call Script
                 * 3. Stats Panel
                 * 4. Skip Contacts + Finish Calling Buttons
                 * 5. Feedback
                 *
                 * On desktop, the order is:
                 * 1. Contact Sidebar
                 * 2. Stats Panel
                 * 3. Skip Contacts + Finish Calling Buttons
                 * 4. Feedback
                 * 5. Call Script
                 */
              }
              <ContactSidebar id={id} mode={mode} class='order-1 rounded-xl p-5 bg-gray-100 shadow-sm hover:bg-gray-200 hover:shadow-none w-full lg:w-1/3 lg:flex-shrink' />
              <PhonebankStatsPanel id={id} mode={mode} class='order-3 lg:order-2 rounded-xl p-5 shadow-sm w-full lg:w-1/3 lg:flex-shrink' />

              <div class='order-4 lg:order-3 flex justify-center gap-2 w-full lg:w-auto'>
                <SkipContactButton id={id} mode={mode} />
                <FinishCallingButton id={id} mode={mode} />
              </div>

              <Feedback phonebankId={id} class='order-5 lg:order-4 mx-auto w-full lg:w-auto' />
              <CallScript id={id} mode={mode} class='order-2 lg:order-5 h-full w-2/3 flex-grow lg:flex-shrink lg:flex-grow-0' />

            </div>
          </>
          : <Spinner class='text-blue-700 w-16 mx-auto mt-10' />}
      </div>
    </div>
  )
}

export default Phonebank
