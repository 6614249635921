import App from './components/app'
import './style/index.css'
import { init as SentryInit } from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing/esm/browser'

if (typeof window !== 'undefined') {
  SentryInit({
    dsn: 'https://bc66140a3fe04fa7aa2a8cad92b2474d@o435207.ingest.sentry.io/5552572',
    integrations: [
      new BrowserTracing(),
    ],

    // TODO: lower the sample rate
    tracesSampleRate: 1.0,
    beforeBreadcrumb: (breadcrumb) => {
      if (breadcrumb.type === 'http' && breadcrumb.data?.url?.startsWith('https://analytics.turbovpb.com')) {
        return null
      }
      return breadcrumb
    }
  })
}

export default App
