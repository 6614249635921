import { VNode } from 'preact'

export interface User {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phone?: string,
  googleVoice: boolean
}

export function isUser(user: User | null | undefined): user is User {
  return !!user
}

// TODO this should be just an object with string keys
export interface Contact {
  id: string,
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
  additionalFields?: { [key: string]: string }
}

export interface Script {
  firstPage: string,
  pages: { [key: string]: ScriptPage }
}

export function isScript(script: Script | ScriptQuestion): script is Script {
  return typeof (script as any).firstPage === 'string'
}

export interface ScriptPage {
  questions: ScriptQuestion[]
}

export interface ScriptQuestion {
  text: string,
  /** Column name where the results will be saved */
  title: string,
  responses: ResponseOption[]
}

export type ResponseOption = ResponseButton | ResponseInput | ResponseSmsLink

export interface Color {
  red?: number,
  green?: number,
  blue?: number,
  alpha?: number
}

export const toRgbColor = ({ red = 0, green = 0, blue = 0, alpha = 1 }: Color) => `rgba(${Math.floor(red * 255)}, ${Math.floor(green * 255)}, ${Math.floor(blue * 255)}, ${alpha})`

export interface ResponseOptionCommon {
  type: string,
  label: string,
  /**
   * If the user selects this response, go to the given page.
   * If this value is undefined, stay on the same page.
   * If it is a string but does not correspond to a page in the script, load the next contact.
   */
  goToPage?: string,
  /** @deprecated */
  nextQuestion?: ScriptQuestion
}

export interface ResponseButton extends ResponseOptionCommon {
  type: 'button',
  value: string,
  /** Intended to be used to add Tailwind classes for styling */
  textClass?: string,
  bgClass?: string,
  color?: Color,
  backgroundColor?: Color
}

export interface ResponseInput extends ResponseOptionCommon {
  type: 'input'
}

export interface ResponseSmsLink extends ResponseOptionCommon {
  type: 'sms',
  value: string,
  body: string,
  /** Intended to be used to add Tailwind classes for styling */
  textClass?: string,
  bgClass?: string,
  color?: Color,
  backgroundColor?: Color
}

export interface ContactSidebarSettings {
  rows: ContactSidebarRow[]
}

export interface ContactSidebarRow {
  pattern: string,
  bold?: boolean,
  fontSize?: number,
  color?: Color,
  /** Intended to be used to add Tailwind classes for styling */
  classes?: string,
}

// TODO: remove this type
export interface PhonebankDetails {
  id: string,
  title: string,
}

export interface CreatePhonebankResponse {
  id: string,
  isNew: boolean,
  // This is only included when the phonebank is first created
  apiKey?: string
}

export interface PhonebankSettings {
  id: string,
  mode?: PhonebankMode,
  title: string,
  spreadsheetId: string,
  script: Script,
  sidebar?: ContactSidebarSettings,
  questionOrder: string[]
}

export enum PhonebankMode {
  NORMAL = 'normal',
  TRAINING = 'training',
  DEMO = 'demo'
}

export interface PhonebankResult {
  // contact id within phonebank
  id: string,
  contactedBy: Pick<User, 'firstName' | 'lastName' | 'email'>
  loadedAt: string,
  resultsSavedAt: string,
  responses: ScriptState
}

export interface PhonebankResults extends Pick<PhonebankSettings, 'questionOrder'> {
  results: PhonebankResult[]
}

export interface ScriptPageState {
  pageTitle: string,
  responses: { [key: string]: string }
}

export type ScriptState = ScriptPageState[]

export type NotificationDetails = {
  icon: VNode,
  title: string,
  text?: string,
  timeout?: number,
  onDismiss?: () => void,
  key?: string
}
