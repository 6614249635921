import { h, FunctionComponent } from 'preact'
import { useEffect } from 'preact/hooks'
import PhonebankComponent from '../../components/phonebank'
import { PhonebankMode } from '../../lib/types'
import { configureScope as configureSentryScope } from '@sentry/browser'
import useUserPhonebanks from '../../hooks/useUserPhonebanks'

const DemoPhonebank: FunctionComponent = () => {
  configureSentryScope((scope) => scope.setTransactionName('Phonebank - Demo'))
  const { mutateUserPhonebanks } = useUserPhonebanks(PhonebankMode.DEMO)

  // Restart the stats
  useEffect(() => {
    mutateUserPhonebanks([{
      id: 'DEMO',
      title: 'Demo Phonebank',
      isActive: true,
      totalContacts: 100,
      contactsLeft: 100,
      inProgress: 1,
      userCalls: 0,
      userCallsThisWeek: 0,
      completed: 0
    }], false)
  }, [])

  return (
    <PhonebankComponent id={'DEMO'} mode={PhonebankMode.DEMO} />
  )
}

export default DemoPhonebank
