import { h, FunctionalComponent } from 'preact'
import { PhoneOutgoingIcon } from '@heroicons/react/outline'
import usePhonebank from '../hooks/usePhonebank'
import { PhonebankMode } from '../lib/types'
import Router, { Route } from 'preact-router'

const Logo: FunctionalComponent = () => (
  <PhoneOutgoingIcon className='h-5 w-5 inline' />
)

const NormalLogoAndName: FunctionalComponent = () => (
  <div class='font-semibold text-indigo-800'>
    <a href='/' title='Home' class='flex items-center'>
      <Logo />
      <span class='ml-2 text-lg'>Turbo Phonebank</span>
    </a>
  </div>
)

const LogoAndPhonebankTitle: FunctionalComponent<{ id: string, training: string }> = ({ id, training }) => {
  const mode = training === 'training' ? PhonebankMode.TRAINING : PhonebankMode.NORMAL
  let { title } = usePhonebank(id, mode, false)

  if (!title) {
    return (
      <NormalLogoAndName />
    )
  }

  if (mode === PhonebankMode.TRAINING) {
    title += ' (Training)'
  }

  return (
    <div class='font-bold text-indigo-800'>
      <a href='/' title='Home'>
        <Logo />
      </a>
      <span class='ml-2 truncate max-w-xs sm:max-w-full inline-block align-middle overflow-hidden overflow-ellipsis' title={title}>{title} </span>
    </div>
  )
}

const LogoAndName: FunctionalComponent = () => (
  <Router>
    <Route path='/p/:id/:training?' component={LogoAndPhonebankTitle} />
    <NormalLogoAndName default={true} />
  </Router>
)

export default LogoAndName
