import { h, FunctionalComponent, Fragment } from 'preact'
import { useEffect, useState, useCallback } from 'preact/hooks'
import useUser from '../../hooks/useUser'
import useSupabase from '../../hooks/useSupabase'
import useCurrentContact from '../../hooks/useCurrentContact'
import useScriptState from '../../hooks/useScriptState'
import { PhonebankMode } from '../../lib/types'
import { route } from 'preact-router'
import { StopIcon } from '@heroicons/react/solid'
import ConfirmModal from '../async/confirm-modal'

const FinishCallingButton: FunctionalComponent<{ id: string, mode: PhonebankMode }> = ({ id, mode }) => {
  const supabase = useSupabase()
  const { user } = useUser()
  const { contact, mutateCurrentContact } = useCurrentContact(id, mode, false)
  const { hasQuestionResponses } = useScriptState(id, mode, false)
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(undefined as Error | undefined)

  useEffect(() => {
    if (error) {
      throw error
    }
  }, [error])

  const finishCalling = useCallback(async () => {
    mutateCurrentContact(undefined, false)

    if (mode === PhonebankMode.NORMAL) {
      const { error } = await supabase.from('results')
        .delete({ returning: 'minimal' })
        .match({
          phonebank: id,
          id: contact!.id
        })
        .limit(1)
      if (error) {
        setError(new Error(`Error skipping contact: ${error.message}`))
      }
    }

    if (user) {
      route('/dashboard')
    } else {
      route('/')
    }
  }, [contact?.id])

  const onClick = useCallback(async (e: any) => {
    e.preventDefault()

    // show a confirmation dialog if there is unsaved script state
    // that would be discarded by leaving the phonebank now
    if (hasQuestionResponses) {
      setShowModal(true)
    } else {
      return finishCalling()
    }
  }, [hasQuestionResponses, finishCalling])

  return (
    <>
      {showModal
        ? <ConfirmModal title='Discard Script Responses?'
          message='Leaving the phonebank now will discard the responses for the current contact and another caller will be assigned to this contact. Would you like to continue?'
          confirmButtonText='Leave Phonebank'
          onConfirm={() => {
            setShowModal(false)
            finishCalling()
          }}
          onDismiss={() => setShowModal(false)}
        />
        : null}
      <button
        onClick={onClick}
        type="button"
        class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-400 hover:text-gray-700 bg-white hover:bg-gray-50"
      >
        <StopIcon class='h-4 w-4 mr-1' />
        Finish Calling
      </button>
    </>
  )
}

export default FinishCallingButton
