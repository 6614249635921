import { h, FunctionalComponent, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { InformationCircleIcon, ExclamationIcon, XIcon } from '@heroicons/react/solid'

type AlertType = 'INFO' | 'WARNING'

const Alert: FunctionalComponent<{
  title: string,
  description: string,
  type: AlertType,
  disableDismiss?: boolean,
  class?: string,
  onClick?: () => void
}> = ({
  title,
  description,
  type,
  class: className,
  onClick,
  disableDismiss = false
}) => {
    const [hidden, setHidden] = useState(false)

    if (hidden) {
      return (
        <>
        </>
      )
    }

    return (
      <div class={`rounded-md p-4 max-w-lg mx-auto \
      ${className || ''} \
      ${type === 'INFO' ? 'bg-blue-50' : 'bg-yellow-50'} \
      ${typeof onClick === 'function' ? `cursor-pointer ${(type === 'INFO' ? 'hover:bg-blue-100' : 'hover:bg-yellow-100')}` : ''}`}
        onClick={onClick}>
        <div class='flex'>
          <div class='flex-shrink-0'>
            {type === 'INFO'
              ? <InformationCircleIcon className='h-5 w-5 text-blue-400' />
              : <ExclamationIcon className='h-5 w-5 text-yellow-400' />}
          </div>
          <div class='ml-3 flex-1 md:flex md:justify-between'>
            <p>
              <h3 class={`text-sm font-medium ${type === 'INFO' ? 'text-blue-800' : 'text-yellow-900'}`}>
                {title}
              </h3>
              <div class={`mt-2 text-sm ${type === 'INFO' ? 'text-blue-700' : 'text-yellow-700'}`}>
                <p> {description} </p>
              </div>
            </p>
          </div>
          {!disableDismiss && (
            <div class='ml-auto pl-3'>
              <div class='-mx-1.5 -my-1.5'>
                <button class={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2
            ${type === 'INFO'
                    ? 'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600'
                    : 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600'}`}
                  onClick={(e) => {
                    setHidden(true)
                    e.cancelBubble = true
                  }}>
                  <span class='sr-only'>Dismiss</span>
                  <XIcon className='h5 w-5' aria-hidden='true' />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

export default Alert
