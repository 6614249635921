import { h, FunctionalComponent, JSX, Fragment } from 'preact'
import { Contact, ContactSidebarSettings, PhonebankMode, toRgbColor } from '../../lib/types'
import { ReadonlyDeep } from 'type-fest'
import useUser from '../../hooks/useUser'
import usePhonebank from '../../hooks/usePhonebank'
import useCurrentContact from '../../hooks/useCurrentContact'
import { useEffect, useRef } from 'preact/hooks'
import { scrollIntoView } from '../../lib/scroll'

const PHONE_NUMBER_REGEX = /1?\W*([1-9][0-9][0-9])\W*([0-9]{3})\W*([0-9]{4})(\se?x?t?(\d*))?/g
const FULL_PHONE_NUMBER_REGEX = /(1?\W*[1-9][0-8][0-9]\W*[0-9]{3}\W*[0-9]{4}(?:\se?x?t?(?:\d*))?)/g

function parsePhoneNumber(phoneNumber: string, googleVoice?: boolean): { display: string, link: string } | null {
  const match = PHONE_NUMBER_REGEX.exec(phoneNumber)
  if (!match) {
    return null
  }
  return {
    display: `(${match[1]}) ${match[2]}-${match[3]}`,
    link: googleVoice
      ? `https://voice.google.com/u/0/calls?a=nc,%2B1${match[1]}${match[2]}${match[3]}`
      : `tel:+1${match[1]}${match[2]}${match[3]}`
  }
}

const DefaultContactSidebar: FunctionalComponent<{ contact: Contact }> = ({ contact }) => {
  const { user } = useUser()
  const phoneNumber = parsePhoneNumber(contact.phoneNumber || '', user?.googleVoice)

  if (phoneNumber) {
    return (
      <a href={phoneNumber.link}>
        <h2 class='text-3xl'>{contact.firstName} {contact.lastName}</h2>
        <a class='text-2xl text-blue-600'>{phoneNumber.display}</a>
      </a>
    )
  } else {
    return (
      <>
        <h2 class='text-3xl'>{contact.firstName} {contact.lastName}</h2>
        <p>(Unable to find phone number)</p>
      </>
    )
  }
}

const CustomContactSidebar: FunctionalComponent<{ contact: Contact, settings: ReadonlyDeep<ContactSidebarSettings> }> = ({ contact, settings }) => {
  const { user } = useUser()

  // This is the link that will be used for the whole contact panel
  // (as opposed to additional links that can be clicked within the panel)
  let phoneNumberLink: string | undefined
  const rows = (
    <>
      {settings.rows.map((row) => {
        const classes = []
        let css
        if (row.fontSize) {
          if (row.fontSize > 10 && row.fontSize < 14) {
            classes.push('text-lg mb-1')
          } else if (row.fontSize >= 14 && row.fontSize < 16) {
            classes.push('text-xl mb-2')
          } else if (row.fontSize >= 16 && row.fontSize < 18) {
            classes.push('text-2xl mb-3')
          } else if (row.fontSize >= 18) {
            classes.push('text-3xl mb-4')
          }
        }
        if (row.bold) {
          classes.push('font-bold')
        }
        if (row.color) {
          css = `color: ${toRgbColor(row.color)}`
        }
        // This is only used by the demo
        if (row.classes) {
          classes.push(row.classes)
        }

        let text = row.pattern
        if (text) {
          for (let field in contact.additionalFields) {
            text = text.replaceAll(`{{${field}}}`, contact.additionalFields[field])
          }
        }

        let inner: any
        let phoneNumber
        if ((phoneNumber = parsePhoneNumber(text, user?.googleVoice))) {
          if (!phoneNumberLink) {
            phoneNumberLink = phoneNumber.link
          }
          // Look for a phone number in the row text
          // For example, this will find the number in a line with some non-number leading text
          const parts = text.split(FULL_PHONE_NUMBER_REGEX)
          const components: JSX.Element[] = []

          for (let part of parts) {
            if (FULL_PHONE_NUMBER_REGEX.test(part)) {
              components.push((
                <a href={phoneNumber.link} target={user?.googleVoice ? '_blank' : '_self'}>
                  {phoneNumber.display}
                </a>
              ))
            } else {
              components.push((
                <span>{part}</span>
              ))
            }
          }
          inner = <span class='space-x-1'>{components}</span>
        } else {
          inner = text || (
            <br />
          )
        }
        return (
          <div class={classes.join(' ')} style={css}>{inner}</div>
        )
      })}
    </>
  )
  return (
    <a href={phoneNumberLink} target={user?.googleVoice ? '_blank' : '_self'}>
      {rows}
    </a>
  )
}

const ContactSidebar: FunctionalComponent<{ id: string, mode: PhonebankMode, class?: string }> = ({ id, mode, class: className }) => {
  const { sidebar, phonebankLoaded } = usePhonebank(id, mode, false)
  const { contact } = useCurrentContact(id, mode, false)

  if (!phonebankLoaded || !contact) {
    return null
  }

  // Scroll to the top of the sidebar
  const div = useRef(null as any)
  useEffect(() => {
    scrollIntoView(div.current)
  }, [contact])

  return (
    <div class={`text-center ${className}`} ref={div}>
      {sidebar
        ? <CustomContactSidebar contact={contact} settings={sidebar} />
        : <DefaultContactSidebar contact={contact} />}
    </div>
  )
}

export default ContactSidebar
